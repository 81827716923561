<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text class="card-padding">
          <v-row>
            <v-col cols="12" sm="6" md="3" xs="12" lg="3">
              <label class="text-md text-typo font-weight-bolder ms-1"
                ><span class="red--text"><strong>* </strong></span>From (Start
                date)</label
              >

              <v-menu
                ref="start_date_menu"
                v-model="start_date_menu"
                :close-on-content-click="false"
                :return-value.sync="start_date_menu"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="start_date"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="46"
                    placeholder="Start Date"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="green lighten-1"
                  header-color="primary"
                  v-model="start_date"
                  :max="end_date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="start_date_menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.start_date_menu.save(start_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="3" xs="12" lg="3">
              <label class="text-md text-typo font-weight-bolder ms-1"
                ><span class="red--text"><strong>* </strong></span>To (End
                date)</label
              >

              <v-menu
                ref="end_date_menu"
                v-model="end_date_menu"
                :close-on-content-click="false"
                :return-value.sync="end_date_menu"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="end_date"
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                      mt-5
                    "
                    dense
                    flat
                    filled
                    solo
                    height="46"
                    placeholder="To Date"
                    persistent-hint
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="green lighten-1"
                  header-color="primary"
                  v-model="end_date"
                  :min="start_date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="end_date_menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.end_date_menu.save(end_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="
                  text-capitalize
                  btn-ls btn-primary
                  bg-success
                  py-3
                  px-6
                  mt-11
                "
                @click="search"
                >Search</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="dispatchList"
            fixed-header
            class="table"
          >
            <template v-slot:[`item.dispatch_status`]="{ item }">
              <span>
                <v-chip
                  label
                  small
                  :ripple="false"
                  :color="syncStatus(item).color"
                >
                  {{ syncStatus(item).label }}
                </v-chip>
              </span>
            </template>
            <template v-slot:[`item.dispatch_date`]="{ item }">
              <span>
                {{ formatDate(item.dispatch_date) }}
              </span> </template
            ><template v-slot:[`item.recieved_date`]="{ item }">
              <span>
                {{ formatDate(item.recieved_date) }}
              </span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                @click="createItem(item)"
                icon
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class=""
                color="green"
                v-if="item.status == 'CREATE'"
                >Create </v-btn
              ><v-btn
                @click="viewItem(item)"
                icon
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class=""
                color="green"
                v-if="item.status == 'VIEW'"
                >View
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import dispatchApi from "../../../grn/grn/api";
export default {
  created() {
    this.initialize();
  },
  data() {
    return {
      start_date_menu: false,
      end_date_menu: false,
      start_date: null,
      end_date: null,

      overlay: false,

      dispatchList: [],

      headers: [
        {
          text: "code",
          value: "dispatch_code",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
          sortable: false,
        },
        {
          text: "Mode Of Trasport",
          value: "mode_of_transport",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Vehicle no",
          value: "vehicle_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Conatct No",
          value: "driver_contact_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },

        {
          text: "Type",
          value: "purchase_type",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 150,
          sortable: false,
        },
        {
          text: "Dispatch Date",
          value: "dispatch_date",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Received Date",
          value: "recieved_date",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "Status",
          value: "dispatch_status",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
        {
          text: "",
          value: "action",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 75,
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async setDates() {
      let date = new Date();
      let d = new Date(date.getFullYear(), date.getMonth(), 1);
      let d1 = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.start_date = this.formatDate(d);
      this.end_date = this.formatDate(d1);
    },
    async initialize() {
      this.overlay = true;

      await this.setDates();
      await this.search();
      this.overlay = false;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    syncStatus(item) {
      if (item.dispatch_status === "PENDING") {
        return { label: "PENDING", color: "rgba(198, 128, 77, 0.12)" };
      } else if (item.dispatch_status === "RECIEVED") {
        return { label: "RECEIVED", color: "rgba(105, 240, 174, 0.5)" };
      } else if (item.dispatch_status === "CONFIRM") {
        return { label: "CONFIRMED", color: "rgb(0, 204, 255, 0.5)" };
      } else {
        return { label: item.dispatch_status, color: "rgba(237, 63, 59, 0.5)" };
      }
    },
    viewItem(item) {
      this.$router.push({
        name: "CreateIrmc",
        params: { data: item, type: "VIEW" },
      });
    },
    createItem(item) {
      this.$router.push({
        name: "CreateIrmc",
        params: { data: item, type: "CREATE" },
      });
    },
    async search() {
      this.overlay = true;
      this.dispatchList = [];
      console.log(this.start_date, this.end_date);
      this.dispatchList = await dispatchApi.getDispatchIrmc(
        this.start_date,
        this.end_date
      );
      if (this.dispatchList.length == 0) {
        this.noDataAlert("No Data Found");
      }
      this.overlay = false;
    },
    noDataAlert(message) {
      this.$swal({
        showConfirmButton: false,
        text: message,
        timer: 3000,
      });
    },
  },
};
</script>

<style></style>
