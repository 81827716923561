import axios from "axios";

export default {
  async getGrn(fromDate, toDate, company_warehouse) {
    return await axios
      .get(
        `dispatch/grn-no?fromDate=${fromDate}&toDate=${toDate}&companyWarehouse=${company_warehouse}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getDeliveryCode(fromDate, toDate, company_warehouse) {
    return await axios
      .get(
        `dispatch/delivery-code?fromDate=${fromDate}&toDate=${toDate}&companyWarehouse=${company_warehouse}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getDispatchIrmc(fromDate, toDate) {
    return await axios
      .get(`dispatch/dispatch-irmc?fromDate=${fromDate}&toDate=${toDate}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getGrnSearchDetails(master_id) {
    return await axios
      .get("grn/search/" + master_id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async savingGrn(req) {
    return await axios
      .post("grn", { data: req })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async importExcel(master_id) {
    return await axios
      .get("grn/import-excel/" + master_id, {
        responseType: "blob",
      })
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "GRN.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err.response.data.message);
      });
  },
};
